export default {
	// 获取页面数据
	getTableData() {
		this.onLoad(true)
		let client_id = JSON.parse(localStorage.getItem(this.sy)).client_id;
		
		if(!this.search.time){
			this.search.time = [];
		}
		console.log(this.search);
		var params = {
			time_type:this.time_type ? this.time_type : this.search.time_type,
			start_time:this.time.length ? this.time[0] : (this.search.time.length ? this.search.time[0]/1000 : ''),
			end_time:this.time.length ? this.time[1] : (this.search.time.length ? this.search.time[1]/1000 + 86400 : ''),
			// status:this.search.status,
			logistics_id:this.search.logistics_id,
			// logistics_odd:this.logistics_odd,
			logistics_odd:this.search.search_type == 'logistics_odd' ? this.logistics_odd : '',
			line_num:this.search.search_type == 'line_num' ? this.logistics_odd : '',
			client_name:this.search.search_type == 'client_name' ? this.logistics_odd : '',
			product_name:this.search.search_type == 'product_name' ? this.logistics_odd : '',
			dispose_status:this.search.dispose_status,
			client_id:client_id,
			time_out: this.time_type,// 0 不查  1查
			order_by_field:this.order_by_field,//排序
			order:this.order,
			small_status:this.small_status,
			page: this.$refs.Page.pagination.current,
			limit: this.$refs.Page.pagination.pageSize
		}
		
		this.getCount(params)
		this.axiosGets("Order/list", params, function(res) {
			console.log(res);
			if (res.data.code == 200) {
				this.onLoad(false)
				// this.msgSuccess(res.data.message)
				this.tableData = res.data.data.data
				this.total = res.data.data.total
			} else {
				this.msgError(res.data.message)
			}

		})
	},
	
	//排序
	sorts(column){
		console.log(column);
		this.order_by_field = column.prop;
		if(column.order == "ascending") {
			this.order = 'ASC'
		} else if(column.order == "descending") {
			this.order = 'DESC'
		} else {
			this.order = ''
			this.order_by_field = '';
		}
			this.getTableData();
	},
	//获取所有客户
	getClient(){
		let  params = {
			account_type_id:3,
			page: 1,
			limit: 10000000
		}
		this.axiosPost("Client/clientList", params, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
				this.clientList = res.data.data.data;
				// console.log(this.opt1);
			}
		})
	},
	
	//获取客户下的产品
	getProduct(){
		let  params = {
			// client_id:val,
			page: 1,
			limit: 10000000
		}
		this.axiosPost('Product/list', params , res => {
			if(res.data.code === 200) {
				this.productList = res.data.data.data;
			}
		})
	},
	
	set(val) {
		this.small_status = val;
		this.$refs.Page.pagination.current = 1;
		this.getTableData();
	},
	set1(val) {
		this.search.dispose_status = val;
		console.log(event.target.parentNode)
		// document.getElementsByClassName('.show').remove('show')
		// if(event.target.className == 'div') {
		// 	event.target.className += ' show'
		// } else {
		// 	event.target.parentNode.className += ' show'
		// }
		// this.$refs.Page.pagination.current = 1;
		// this.getTableData();
	},
	set2() {
		this.search =  {
					time_type: "",
					search_type: 'logistics_odd',
					time: [],
					status: "",
					logistics_id: "",
					dispose_status: 3, //处理状态
				};
				this.small_status = ''
				this.order_by_field = ''
				this.order = ''
		// this.search.dispose_status = val;
		
	},
	
	
	showInfo(all_info){
		this.all_info = all_info;
		this.isShow1 = true
	},
	
	getTime(){
		
		if(!this.time_type){
			this.time_type = 1;
			let date = new Date();
			this.time = [0, Math.floor(date.getTime()/1000) - 86400]
		}else {
			this.time_type = 0;
		}
			this.getTableData()
	},
	
	getTime1(){
		
		let date = new Date(new Date().toLocaleDateString()).getTime();
		this.search.time_type = 'created_at'
		this.search.time[0] = (date/1000 - (86400*3))*1000;//3天前
		this.search.time[1] =( date/1000 - 86400)*1000;//1天前 上边传参时加了1天
		console.log(this.search)
		this.getTableData()
	},
	
	getTime2(){
		let date = new Date(new Date().toLocaleDateString()).getTime();
		this.search.time_type = 'created_at'
		this.search.time[0] = (date/1000 - (86400*10))*1000;//10天前
		this.search.time[1] = (date/1000 - (86400*3) - 86400)*1000;//4天前
		this.getTableData()
	},

	
	//获取所有物流
	getLogistics(){
		let  params = {}
		this.axiosPost("Public/logisticsList", params, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
				this.opt = res.data.data;
				// console.log(this.opt1);
			}
		})
	},
	
	
	//手动刷新物流信息
	getLogisticsDetail(){
		this.onLoad(true);
		this.axiosPost("LogisticsManagement/AllCreate", {}, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
				this.msgSuccess('物流信息将在10分钟后刷新,请勿重复手动刷新!');
				this.onLoad(false);
				// this.opt = res.data.data;
				// console.log(this.opt1);
			}
		})
	},
	
	getCount(params){
		// let client_id = JSON.parse(localStorage.getItem(this.sy)).client_id;
		// let params = {client_id:client_id}
		this.axiosPost('LogisticsManagement/statistics', params , res => {
			if(res.data.code === 200) {
				if(res.data.data.NoLogistics){
					this.form = res.data.data
				}else {
					this.form = {
						IssueLogistics: {
							num: 0,
							proportion: "0%"
						},
						AllLogistics: 0,
						CollectionLogistics: {
							num: 0,
							proportion: "0%"
						},
						OneLogistics: {
							num: 0,
							proportion: "0%"
						},
						TransportLogistics: {
							num: 0,
							proportion: "0%"
						},
						SignLogistics:{
							num: 0,
							proportion: "0%"
						},
						NoLogistics: {
							num: 0,
							proportion: "0%"
						},
						FigureOut:res.data.data.FigureOut,
						InHand:res.data.data.InHand
					} //统计数据
				}
			}
		})
	},

	see(scope){
		this.$refs.See.form = scope;
		this.$refs.See.getOrder();
		this.$refs.See.isShow = true;
	},


	// submitUpload() {
	// 	this.$refs.img.submit();
	// 	this.$refs.audio.submit();

	// },


	//编辑
	edit(scope) {
		
		this.$refs.Edit.form = scope;
		this.$refs.Edit.getOrder();
		this.$refs.Edit.getGroup();
		this.$refs.Edit.isShow = true;
	},

	
	getTableData1() {
		this.onLoad(true)
		let client_id = JSON.parse(localStorage.getItem(this.sy)).client_id;
		var params = {
			client_id:client_id,
			page: 1,
			limit: 100000
		}
		this.axiosGets("Order/list", params, function(res) {
			// console.log(res);
			if (res.data.code === 200) {
				this.tableData1 = res.data.data.data
				this.Exports(2);
			}

		})

	},

	//导出（选择）
	Exports(scope) {
	
		// console.log(scope);
		let multipleData = this.$refs.tables.selection;
		if (multipleData.length == 0 && scope == 1) {
			this.msgWarning("请选择需要导出的数据");
			return;
		} else if (scope == 2) {
			multipleData = this.tableData1;
		}
	
		// console.log(this.$refs.multipleTable);
		console.log(multipleData);
	
		multipleData = multipleData.map((item) => {
			let time = this.$options.filters['format'](item.first_time) +'-'+ this.$options.filters['format'](item.last_time);
			let stop_time = Math.ceil((this.date.getTime()/1000 - item.last_time)/60/60)
			let treatment_scheme = {}
			if(item.treatment_scheme.length){
				treatment_scheme = item.treatment_scheme[item.treatment_scheme.length-1];
			}
			let type = '';
			if(item.dispose_status == 1) {
				type = '处理完成'
			}else if(item.dispose_status == 2) {
				type = '处理中'
			}
			let obj = {
				line_num:item.line_num,
				logistics_odd:item.logistics_odd,
				logistics_name:item.logistics.logistics_name,
				stop_time:stop_time,
				time:time,
				client_name:item.client_name,
				product_name:item.product_name,
				consignee:item.consignee,
				delivery_time:item.delivery_time,
				shipping_details:item.all_info,
				traces_num:item.traces_num,
				status:item.status,
				remark:item.remark,
				admin:treatment_scheme.admin ? treatment_scheme.admin.name : '',
				lastTime:treatment_scheme.created_at,
				lastRemark:treatment_scheme.remark,
				type:type,
			}
			return obj
		})
	
	
		import("@/vendor/Export2Excel").then(excel => {
			//表格的表头列表
			const tHeader = ['线上订单号','物流单号', '物流公司', '停留时间','揽件时间 & 最新时间','客户名', '产品名', '收货人', '发货时间', '物流详情', '条数', '状态', '问题', '当前处理人', '最后处理时间', '最后备注', '处理状态'
			];
			//工作内容	完成情况	未完成原因	未完成工作的计划完成时间	交付人员	明日计划
	
			//与表头相对应的数据里边的字段
			const filterVal = ['line_num','logistics_odd', 'logistics_name', 'stop_time','time', 'client_name','product_name', 'consignee','delivery_time','shipping_details',
				'traces_num', 'status', 'remark', 'admin', 'lastTime', 'lastRemark', 'type'
			];
			const list = multipleData;
			const data = this.formatJson(filterVal, list);
			console.log(data);
			//这里还是使用export_json_to_excel方法比较好，方便操作数据
			excel.export_json_to_excel(tHeader, data, '物流单列表');
			this.onLoad(false);
		});
	},
	/* 导出数据处理*/
	formatJson(filterVal, jsonData) {
		return jsonData.map(v => filterVal.map(j => {
	
			return v[j]
		}))
	}
}

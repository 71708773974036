<template>
	<div class="p_20 indexBanner">
		<el-dialog :visible.sync="isShow" width="65%" :close-on-click-modal="false" @close="closeDialog">
			<!-- <el-divider></el-divider> -->
			<div class="flex justify_content_between">
				<div style="width: 50%">
					<h3 class="text_center title">派单</h3>
					<div class="text_center flex justify_content_center m_t_20 m_b_20">
						<table style="width: 100%; line-height: 30px" border cellspacing="0">
							<tr>
								<td width="50%">物流公司</td>
								<td>{{ form.logistics.logistics_name }}</td>
							</tr>
							<tr>
								<td>物流单号</td>
								<td>{{ form.logistics_odd }}</td>
							</tr>
							<tr>
								<td>持续时间</td>
								<td>
									{{ form.last_time > 0 ? form.last_time : "暂无" | format }}
									<br />
									{{ form.first_time > 0 ? form.first_time : "暂无" | format }}
								</td>
							</tr>
							<tr>
								<td>当前状态</td>
								<td>{{ form.status }}</td>
							</tr>
							<tr>
								<td>物流详情</td>
								<td>
									<span v-html="form.last_info"></span>
									<el-tag size="mini" class="hand" v-if="form.all_info" @click="showInfo(form.all_info)">...</el-tag>
								</td>
							</tr>
							<tr>
								<td>最后备注</td>
								<td v-if="form.treatment_scheme.length">{{ form.treatment_scheme[form.treatment_scheme.length-1].remark }}</td>
							</tr>
						</table>
					</div>
					<h3 class="text_center title">派单详细</h3>
					<el-form label-width="60px" class="m_t_20">
						<el-form-item label="指派给">
							<!-- <el-select v-model="form1.group_id">
                <el-option
                  v-for="item in opt"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select> -->
							<el-cascader :options="opt" v-model="group_id" @change="handleChange" :props="{ label: 'name', value: 'id', children: 'group' }"></el-cascader>
						</el-form-item>
						<el-form-item label="备注">
							<el-input type="textarea" v-model="form1.remark"></el-input>
						</el-form-item>

						<el-form-item label="">
							<el-upload class="upload-demo" :action="BaseURL + 'Public/uploadFile'" :on-success="uploadSuccess" :headers="{ token: token }" :file-list="fileList">
								<!-- :on-remove="handleRemove" -->
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" append-to-body>
								<img width="100%" :src="dialogImageUrl" alt="" />
							</el-dialog>
						</el-form-item>
						<el-form-item>
							<el-button @click="closeDialog">取 消</el-button>
							<el-button type="primary" @click="submit">确 认</el-button>
						</el-form-item>
					</el-form>
				</div>

				<div style="width: 48%">
					<h3 class="text_center title">流转记录</h3>

					<div class="flex m_t_20 justify_content_around">
						<div style="min-height: 400px">
							<el-steps direction="vertical" space="270px" :active="order.length">
								<el-step v-for="(item, index) in order" :key="item.id">
									<i slot="icon">
										<span v-if="order.length - index == 1">始</span>
										<span v-else-if="
                        order.length - index == order.length &&
                        form.treatment_scheme.length &&
                        form.treatment_scheme[form.treatment_scheme.length - 1]
                          .status == 1
                      ">终</span>
										<span v-else> {{ order.length - index }}</span>
									</i>
								</el-step>
							</el-steps>
						</div>
						<div style="width: 70%">
							<div v-for="(item, index) in order" :key="item.id" style="height: 270px">
								<div class="flex align_items_center">
									<div class="img">
										<img :src="item.admin.avatar_url" alt="" style="width: 60px; height: 60px; border-radius: 50%" />
									</div>
									<div class="p_l_20">
										<!-- <span>{{ item.admin ? item.admin.name : "" }}（123）</span> -->
										<span v-if="item.admin && item.client && item.group">{{
                      item.admin
                        ? index == 0 && item.status == 1
                          ? item.admin.name +
                            " (" +
                            item.admin.client.name +
                            " > " +
                            item.admin.group.name +
                            ")"
                          : item.admin.name +
                            " (" +
                            item.admin.client.name +
                            " > " +
                            item.admin.group.name +
                            ")" +
                            " → " +
                            item.client.name +
                            "(" +
                            item.group.name +
                            ")"
                        : ""
                    }}</span>
										<p>{{ item.created_at | format }}</p>
									</div>
								</div>
								<div class="m_t_10 p_10" style="background: #eee; height: 100px">
									{{ item.remark }}
								</div>
								<div v-if="item.file_info" class="m_t_10">
									<span v-for="(it, i) in item.file_info" :key="i" class="m_r_10">
										<!-- <img :src="imgURL + it.url + it.name" height="60px" alt=""> -->
										<el-image style="height: 50px; width: 50px" :src="imgURL + it.url + it.name" :preview-src-list="[imgURL + it.url + it.name]">
										</el-image>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-dialog :visible.sync="isShow1" append-to-body @close="(all_info = '' ,isShow1 = false)">
				<div class="p_20">
					<p v-html="all_info"></p>
				</div>
			</el-dialog>

			<!-- <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submit">确 认</el-button>
      </span> -->
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				isShow1: false,
				all_info: '',
				fileList: [],
				order: [],
				opt: [],
				form: {
					logistics: {
						logistics_name: ""
					},
					treatment_scheme: [{
						remark: ""
					}],
				},

				form1: {
					image_ids: [],
					remark: "",
					status: 2,
					mobile: "",
					note_template: "",
					order_id: "",
					logistics_odd: "",
					group_id: "",
					client_id: "",
				},
				//图片上传
				imageUrl: "",
				group_id: [],
				dialogImageUrl: "",
				dialogVisible: false,
				disabled: false,
				token: JSON.parse(localStorage.getItem(this.sy)).token,
				// dialogVisible: false,
				// disabled: false
			};
		},
		methods: {

			showInfo(all_info) {
				this.all_info = all_info;
				this.isShow1 = true
			},
			getGroup() {
				// let a = JSON.parse(localStorage.getItem(this.sy)).group_id
				let params = {
					client_id: this.form.client_id + ',' + (this.form.logistics_id == 1 ? 2 : 7)
				};
				this.axiosPost("Public/clientGroupList", params, (res) => {
					if (res.data.code == 200) {
						this.opt = res.data.data;
					}
				});
			},

			getOrder() {
				// let a = JSON.parse(localStorage.getItem(this.sy)).group_id;
				let params = {
					logistics_odd: this.form.logistics_odd,
					// group_id:a,
					// client_id:this.form.client_id
				};
				this.axiosPost("SendOrders/list", params, (res) => {
					// console.log(res);
					if (res.data.code === 200) {
						this.order = res.data.data;
					}
				});
			},

			handleChange() {
				console.log(this.group_id);
				this.form1.client_id = this.group_id[0];
				this.form1.group_id = this.group_id[1];
			},

			uploadSuccess(res) {
				console.log(res);
				if (res.code == 200) {
					this.form1.image_ids.push(res.data);
				}
			},
			handleRemove(file) {
				console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				console.log(file);
			},
			//提交
			submit() {
				if (!this.form1.group_id) {
					this.msgError("请选择指派组");
					return;
				}
				let params = this.form1;

				params.order_id = this.form.id;
				params.logistics_odd = this.form.logistics_odd;
				params.image_ids = this.form1.image_ids.toString();
				if (!params.group_id) {
					params.client_id = this.form.client_id;
					params.group_id = JSON.parse(localStorage.getItem(this.sy)).group_id;
				}
				// params.group_id = this.form1.group_id,

				// {
				//   order_id:this.form.id,
				//   client_id:this.form.client_id,
				//   group_id:this.form1.group_id,
				//   remark:this.form1.remark,
				//   image_ids:this.form1.image_ids.toString(),
				//   status:2
				// }

				this.edit(params);
			},

			//编辑
			edit(params) {
				this.axiosPost("Order/sendOrders", params, (res) => {
					if (res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					} else {
						this.msgError(res.data.message);
					}
				});
			},

			//图片上传
			handleAvatarSuccess(res) {
				console.log(res);
				// console.log(file);
				//图片id
				this.form.image_id = res.data.id;
				//图片url
				this.imageUrl = this.imgURL + res.data.image_url;
				// this.form.url = res.data.image_url;
				// this.dialogVisible = true;
			},

			//关闭窗口 清空数据
			closeDialog() {
				(this.form = {
					logistics: {
						logistics_name: ""
					},
					treatment_scheme: [{
						remark: ""
					}],
				}),
				(this.form1 = {
					image_ids: [],
					remark: "",
					status: 2,
					mobile: "",
					note_template: "",
					order_id: "",
					logistics_odd: "",
					group_id: "",
					client_id: "",
				});

				(this.upImg = {
					label: "图片",
					imageUrl: "",
					name: "file",
					width: "1080",
					height: "768",
					file: "",
				}),
				(this.imageUrl = "");
				this.order = [];
				this.group_id = [];
				this.isShow = false;
			},
		},
	};
</script>

<style scoped>
	.title {
		background: #66b1ff;
		margin: 0;
		padding: 10px 0px;
		color: #fff;
	}

	.img {
		border: 1px solid #000;
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}

	.el-step:last-child {
		flex-basis: 0 !important;
	}

	/deep/.el-step:first-child .el-step__line-inner {
		border-style: dashed;
	}
</style>

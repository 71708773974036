<template>
  <div class="p_20 indexBanner">
    <el-dialog
      :visible.sync="isShow"
      width="35%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <!-- <el-divider></el-divider> -->
      <h3 class="text_center title">派单</h3>
      <div class="text_center flex justify_content_center m_t_20 m_b_20">
        <table style="width: 100%; line-height: 30px" border cellspacing="0">
          <tr>
            <td width="50%">物流公司</td>
            <td>{{form.logistics.logistics_name}}</td>
          </tr>
          <tr>
            <td>物流单号</td>
            <td>{{form.logistics_odd}}</td>
          </tr>
          <tr>
            <td>持续时间</td>
            <td>
				{{form.last_time > 0 ? form.last_time: '暂无'|format }} <br> 
				{{form.first_time > 0 ? form.first_time : '暂无'|format}}
			</td>
          </tr>
          <tr>
            <td>当前状态</td>
            <td>{{form.status}}</td>
          </tr>
		<tr>
			<td>物流详情</td>
			<td>
				<span v-html="form.last_info"></span>
				<el-tag size="mini" class="hand" v-if="form.all_info" @click="showInfo(form.all_info)">...</el-tag>
			</td>
		</tr>
		<tr>
			<td>最后备注</td>
			<td v-if="form.treatment_scheme.length">{{ form.treatment_scheme[form.treatment_scheme.length-1].remark }}</td>
		</tr>
        </table>
      </div>

      <h3 class="text_center title">流转记录</h3>

      <div class="flex m_t_20 justify_content_around">
        <div style="min-height:400px;">
          <el-steps direction="vertical" space="270px" :active="order.length">
            <el-step v-for="(item,index) in order" :key="item.id">
              <i slot="icon">
                <span v-if="order.length - index == 1">始</span>
                <span v-else-if="order.length - index == order.length &&form.treatment_scheme.length&&form.treatment_scheme[form.treatment_scheme.length-1].status == 1">终</span>
                <span v-else> {{order.length - index}}</span>
              </i>
            </el-step>
          </el-steps>
        </div>
        <div style="width:70%;" >
					<div v-for="(item,index) in order" :key="item.id" style="height:270px;">
						<div class="flex align_items_center">
							<div class="img">
								<img :src="item.admin.avatar_url" alt="" style="width:60px;height:60px;border-radius:50%">
							</div>
							<div class=" p_l_20">
								<!-- <span>{{item.admin ? item.admin.name : ''}}（123）</span>	 -->
                    <span v-if="item.admin&&item.client&&item.group">{{ item.admin ? (index == 0&&item.status == 1 ? item.admin.name+' ('+item.admin.client.name+' > ' + item.admin.group.name+')' : item.admin.name+' ('+item.admin.client.name+' > ' + item.admin.group.name+')'  +' → '+ item.client.name+'('+item.group.name+')') : "" }}</span>
                    <p>{{item.created_at|format}}</p>	
							</div>	
						</div>
						<div class=" m_t_10 p_10" style="background:#eee;height:100px;">
              {{item.remark}}
						</div>
            <div v-if="item.file_info" class="m_t_10">
                  <span
                    v-for="(it,i) in item.file_info"
                    :key="i"
                    class="m_r_10"
                  >
                <!-- <img :src="imgURL + it.url + it.name" height="60px" alt=""> -->
                <el-image 
                  style=" height: 50px;width:50px"
                  :src="imgURL + it.url + it.name" 
                  :preview-src-list="[imgURL + it.url + it.name]">
                </el-image>
              </span>
              
            </div>
					</div>
				</div>
      </div>

		<el-dialog :visible.sync="isShow1" append-to-body @close="(all_info = '' ,isShow1 = false)">
			<div class="p_20">
				<p v-html="all_info"></p>
			</div>
		</el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    isShow1: false,
	all_info:'',

      form: {
        logistics:{name:''},
        treatment_scheme:[{remark:''}]
      },
      upImg: {
        label: "图片",
        imageUrl: "",
        name: "file",
        width: "1080",
        height: "768",
        file: "",
      },
      order:[],

      //图片上传
      imageUrl: "",
      // dialogVisible: false,
      // disabled: false
    };
  },
  methods: {
	showInfo(all_info){
		this.all_info = all_info;
		this.isShow1 = true
	},
    //提交
    getOrder() {
      // let a = JSON.parse(localStorage.getItem(this.sy)).group_id;
      let params = {
        logistics_odd:this.form.logistics_odd,
        // group_id:a,
        // client_id:this.form.client_id
      }
      this.axiosPost('SendOrders/list' ,  params , res => {
        // console.log(res);
        if(res.data.code === 200) {
          this.order = res.data.data;
        }
      })
    },
	
		
	closeDialog(){
		this.order = [];
		this.isShow = false;
	}
    
  },
};
</script>

<style scoped>
.title {
  background: #66b1ff;
  margin: 0;
  padding: 10px 0px;
  color: #fff;
}

.img {
	border:1px solid #000;
	width: 60px;
	height: 60px;
	border-radius: 50% ;
}
.el-step:last-child {
	flex-basis: 0 !important;
}
/deep/.el-step:first-child .el-step__line-inner {
  border-style:dashed;
}
</style>
